<template>
    <div class="audit">
        <rxNavBar title="制度管理" androidOrIOSFlag="true"></rxNavBar>

        <div class="head">
            <div class="search">
                <div class="search-icon" @click="getQueryHelpCenterOpenInstitution"></div>
                <input type="text" placeholder="搜索制度" v-model="searchContent" @keyup.enter="getQueryHelpCenterOpenInstitution">
            </div>
        </div>
        <div class="appointmentDetail">
          <div v-for="(item, index) in dataListInfo" :key="index" class="relevvant-detail relevvant-detail-border" @click="clickEventInfo(item)" >
        
            <span class="houseRelevantText">{{item.helpCenterName}}</span>
          </div>
     
        </div>
       
    </div>
</template>

<script>
    import {
        Button, Search, DropdownMenu, DropdownItem, Divider, List, NavBar, Picker,PullRefresh,Popup,Tab, Tabs,
    } from 'vant';
    import { getStaffFuntionModelList, queryBaseData,portAuditList,queryHelpCenterOpenInstitution} from "../../getData/getData";
    import {checkAuthList, getStaffId, responseUtil} from "../../libs/rongxunUtil";
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";

    export default {
        components: {
            [Button.name]: Button,
            [Search.name]: Search,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Divider.name]: Divider,
            [NavBar.name]: NavBar,
            [List.name]: List,
            rxNavBar,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [PullRefresh.name]:PullRefresh,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
        },
        data() {
            return {
                active: '100',
                authButtonsList:[],    //权限
                searchContent:'',
                number:0,
                //typeList:[],
                refreshing: false,
                isEmptuyFlag:false,
                loading: false,
                finished: false,
                isShow: false,
                page:{currentPage:1,numberPage:3,},
                orders:[],
                typeList: [],
                dataListInfo: [
                  // {label: "测试子制度1-1",value:'100' },
                  // {label: "测试子制度2-1",value:'101' },
                  // {label: "测试子制度3-1",value:'102' },
                  // {label: "测试子制度4-1",value:'103' },
                  // {label: "测试子制度5-1",value:'104' },
                  // {label: "测试子制度6-1",value:'105' },
                ],
            };
        },
        beforeCreate() {
          
        },
        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if(from.name !== 'viewPointerMenu'){
                localStorage.removeItem('helpcenter_search');
            }
            next()
        },
        created() {
            // this.getStaffFuntionModelList()
            // this.initChooseTypes()
        },
        mounted() {
          let currentLabel = localStorage.getItem('helpcenter_search') ? JSON.parse(localStorage.getItem('helpcenter_search')) : null //先读取local里存储的历史记录
          if(currentLabel != null) {
            this.searchContent = currentLabel
          }
          this.getQueryHelpCenterOpenInstitution()
        },
        methods: {
          getQueryHelpCenterOpenInstitution() {
            localStorage.setItem("helpcenter_search",JSON.stringify(this.searchContent))
            const that = this
            let initData = {
              searchName: that.searchContent
            }
            initData.staff_id = getStaffId()

            queryHelpCenterOpenInstitution(initData).then(function(response) {
              responseUtil.dealResponse(that, response, () => {
                that.dataListInfo = response.data.data.data
                console.log(that.dataListInfo,'that.dataListInfo===')
              })
            })
          },
         clickEventInfo(item) {
          // console.log(item,"item===")
          
          this.$router.push({
          name: 'viewPointerMenu',
          query: {
             data: item.content

            }
          })
         },
    //搜索
    onSearch() {
    },
    //显示隐藏下拉菜单，监听div点击事件，实现点击空白处隐藏下拉列表
    showOrHidden: function () {
      this.isShow = !this.isShow;
      var sp = document.getElementById("downList");
      if (sp) {
        document.addEventListener('click', e => {
          if (!sp.contains(e.target)) {
            this.isShow = false;
          }
        })
      }
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    //装修申请方案查看
    decorationSchemeAuditCheck(item) {
       if (item.approvalStatus == 1||item.approvalStatus == 2){
        this.$router.push({
          name: 'portApprove',
          query:{
              approvalStatus:item.approvalStatus,
              approvalId: item.approvalId,
              reportingTime:item.reportingTime,
              termvalidity:item.termvalidity,
              Numberpeople:item.Numberpeople,
              portPrice:item.portPrice,
              staffName :item.staffName,
              approvalStatusStr:item.approvalStatusStr,
                            
          }
        });     
      }

    },
    //圈经审批
    managerApprove(item){
        this.$router.push({
          name: 'portApprove',
          query: {
              approvalId: item.approvalId,
              reportingTime:item.reportingTime,
              termvalidity:item.termvalidity,
              Numberpeople:item.Numberpeople,
              portPrice:item.portPrice,
              staffName :item.staffName,
              approvalStatusStr:item.approvalStatusStr

          }
        })
    },
    //下拉菜单初始化选择类型
    initChooseTypes: function () {
      let that = this
      let initData = {}
      initData.dbName = ['description']
      initData.fdName = ['APPROVESTATUSMAP']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.APPROVESTATUSMAP
          
          for (let i = 0; i < list.length; i++) {
            that.typeList.push(list[i])
          }
          that.typeList.push({dictionaryValue: '', dictionaryTitle: '全部审批'})
        })
      })
    },

    //更改选择类型
    changeType: function (value, index) {
      //将选择的类型放入本地内存中，
      if (index == '') {
        index = 0;
      }
      localStorage.setItem('currentApproveLabel', JSON.stringify(index))
      this.flag = true
      this.finished = true;
      this.orders = [];
      this.page = {currentPage:1,numberPage:3};
      this.loading = false;
      this.finished = false;
      this.isShow = false;
      this.number = index;
      this.loading = true;
      this.onLoad();
    },

    //下拉刷新
    onRefresh() {
      // 清空列表数据
         this.finished = false;
         this.orders = []
         this.page =  {currentPage:1,numberPage:3}
         // 重新加载数据
         // 将 loading 设置为 true，表示处于加载状态
         this.loading = true;
        this.refreshing = false
         this.onLoad();
         responseUtil.alertMsg(this,"刷新成功")
    },

            //下拉加载
            onLoad: function () {
                let that=this
                let queryData={}
                queryData.type='19'
                queryData.currentPage=that.page.currentPage
                queryData.numberPage=that.page.numberPage
                queryData.addStaffId=getStaffId()
                queryData.approvalStatus=that.number <3?that.number:''
                portAuditList(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.count = response.data.data.pageCount
                        let list=response.data.data.data
                        for (let i = 0; i < list.length; i++) {
                            that.orders.push(list[i])
                        }
                        if(that.refreshing){
                            that.refreshing = false
                        }

                        // 加载状态结束
                        that.loading = false;
                        that.page.currentPage++;
                        // 数据全部加载完成
                        if (that.orders.length >= that.count) {
                            that.finished = true;
                        }
                        //判断数据是否为空，空则显示空状态图
                        if (that.orders.length == 0) {
                            that.isEmptuyFlag = true;
                        } else {
                            that.isEmptuyFlag = false;
                        }                        
                    })
                })      
            },
    //搜索框查询
    goSearch() {
      this.orders = []
      this.page = {currentPage:1,numberPage:3}
      if (this.searchContent == '') {
        location.reload()
      }
      this.onLoad()
    },

    // 权限获取 - 装修申请审批
    getStaffFuntionModelList () {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'portApproval'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
        })
      })
    },
    checkAuthList,
  }
}
</script>

<style lang="less" scoped>
.audit {
  // background: linear-gradient(to bottom, #FFC274 0px, #FF5D3B 100%) repeat scroll 0% 0%;

}

.appointmentDetail {
  width: 92%;
  margin: 0 auto;
  height: auto;
  margin-top:65px;
  /*display: flex;*/
  /*align-items: flex-start;*/
  /*justify-content: space-between;*/
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);

}
.relevvant-detail {
  width: 100%;
  height: 1.2rem;

  font-size: 15px;
  line-height: 1.2rem;
}
.relevvant-detail-border {
  border-bottom: #EFEFEF 1px solid;
  border-radius: 8px;
}

.detail-img {
  height:0.4rem;
  margin-left: 20px;
  padding-top: 0.42rem;
}
.houseRelevantText {
  margin-left: 10px;
  font-family: PingFangSC-Semibold;
  font-weight: bold;
}



.nav_bar_title {
  z-index: 99;
}

.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  // top:100px;
  overflow: hidden;
  background: #f8f8f8;
}

.search {
  margin: 8px 20px 0 20px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(244, 244, 244, 1);
  display: flex;
  align-items: center;
}

.search-icon {
  width: 19px;
  height: 19px;
  margin-left: 9px;
  background-image: url("../../assets/images/search-icon.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

input {
  font-size: 14px;
  margin-left: 10px;
  background-color: rgba(244, 244, 244, 1);
  border: 0;
  width: 250px;
}

input::-webkit-input-placeholder {
  color: rgba(199, 199, 199, 1);
}

.types {
  width: 100%;
  height: 47px;
  display: flex;
}

.type {
  width: auto;
  height: 17px;
  line-height: 17px;
  text-align: left;
  color: #fe5e3a;
  margin: 15px 0 0 15px;
  color: rgba(255, 93, 59, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

.small-icon {
  width: 8px;
  height: 8px;
  margin: 19px 0 0 5px;
  background-image: url("../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  margin: 18px 0 0 5px;
  background-image: url("../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}

.choose-select {
  position: fixed;
  z-index: 6;
  padding-top: 90px;
  width: 100%;
}

.selectList {
  width: 100%;
  height: auto;
  padding: 5px 0 14px 0;
  background-color: white;
}

.select {
  width: 100%;
  height: 35px;
  line-height: 30px;
  font-size: 14px;
  text-align: left;
  margin-left: 15px;
  color: #999999;
}

.selectActive {
  color: #fe5e3a;
}

.h-line {
  height: 1px;
  margin: 0 5px 0 10px;
  background-color: white;
}

/*    下拉菜单*/
.downMenuDiv {
  width: 2.2rem;
}

.auditDiv {
  padding-top: 91px;
}

/*    循环列表DIV*/
.stateList {
  border-radius: 0.3rem;
  width: 93%;
  margin-left: 0.35rem;
  position: relative;
  margin-bottom: 0.4rem;
  background-color: #ffffff;
  padding-bottom: 0.5rem;
}

.stateUnapproval, .statePassed, .stateRejected {
  height: 0.45rem;
  width: fit-content;
  padding: 0 0.13rem;
  border-radius: 0.2rem 0rem;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
  line-height: 0.5rem;
}

/*列表状态样式 1是未审批*/
.stateUnapproval {
  background: -webkit-linear-gradient(left, #FFC274, #FF5D3B);
}

/*列表状态样式 2是已通过*/
.statePassed {
  background: -webkit-linear-gradient(left, #66CB94, #28D3B0);
}

/*列表状态样式 3是已驳回*/
.stateRejected {
  background: -webkit-linear-gradient(left, #E02020, #FF431B);
}

/*列表头部红色重点信息*/
.headSpan {
  float: left;
  margin-right: 0.1rem;
  color: #FF5D3B;
}

/*列表头部小圆点*/
.dot {
  border-radius: 50%;
  width: 0.01rem;
  height: 0.01rem;
  border: 0.05rem solid black;
  float: left;
  margin-top: 0.24rem;
  margin-right: 0.1rem;
}

/*列表头部信息*/
.headInfo {
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  font-size: 15px;
  font-weight: bold;
}

/*列表详情*/
.listDetail {
  position: relative;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.5rem;
}

.listDetail:after {
  content: "";
  display: block;
  clear: both;
}

/*    申请人信息DIV*/
.proposerDiv {
  margin-left: 0.5rem;
  font-size: 13px;
  color: #999999;
  float: left;
}

/*分割线1*/
.rule1 {
  position: absolute;
  left: 2.3rem;
  top: 0.1rem;
  border: 0.01rem solid #f7f7f7;
  height: 1rem;
  float: left;
}

/*分割线2*/
.rule2 {
  position: absolute;
  left: 5.3rem;
  top: 0.1rem;
  border: 0.01rem solid #f7f7f7;
  height: 1rem;
  width: 0.01rem;
  float: left;
}

/*装修预算和改造户型*/
.budgetDiv, .remouldDiv {
  font-size: 13px;
  color: #999999;
  margin-left: 1.68rem;
  float: left;
}

/*   装修预算 */
.budgetDiv {
  width: 1.5rem;
  text-align: center;
}

/*    分割线2*/
/* 申请人取值DIV*/
.proposerValueDiv {
  margin-top: 0.3rem;
  font-size: 14px;
  color: #FF5D3B;
  height: 0.5rem;
  float: left;
  /*margin-left: 0.6rem;*/
  width: 2rem;
  /*border: 1px solid red;*/
  text-align: center;
}

/*装修预算和改造户型取值*/
.budgetValueDiv, .remouldValueDiv {
  font-size: 14px;
  color: #FF5D3B;
  margin-top: 0.3rem;
  float: left;
  width: 2rem;
  /*border: 1px solid red;*/
  text-align: center;
}

.budgetValueDiv {
  margin-left: 0.8rem;
}

.remouldValueDiv {
  margin-left: 1.3rem;
}

/*    分割线3*/
.rule3 {
  border: 0.01rem solid #efefef;
  width: 100%;
  height: 0rem;
  margin-top: 0.1rem;
}

/*    尾部DIV*/
.listFloor {
  margin-left: 0.5rem;
  margin-top: 0.35rem;
  height: 0.73rem;
  line-height: 0.73rem;
}

.listFloor:after {
  content: "";
  display: block;
  clear: both;
}

/*    尾部 创建方式的createMethodsDiv*/
.createMethodsDiv {
  font-size: 13px;
  float: left;
  color: #999999;
}

/*    尾部 创建时间的createTimeDiv*/
.createTimeDiv {
  font-size: 13px;
  float: left;
  margin-left: 0.2rem;
  color: #999999;
  /*margin-top: 0.05rem;*/
}

/*    下部按钮*/
.btn1, .btn2 {
  float: right;
  margin-right: 0.7rem;
  height: 0.82rem;
  width: 2.3rem;
  border-radius: 0.2rem;
  border: 0rem solid transparent;
  box-shadow: none;
  line-height: 0.7rem;
  font-size: 13px;
}

/*按钮*/
.btn1 {
  background: -webkit-linear-gradient(left, #FFC274, #FF5D3B);
  color: white;
}

.btn2 {
  padding: 0.01rem;
  border: 0.03rem solid transparent;
  background-image: linear-gradient(white, white),
  linear-gradient(to right, #FFC274, #FF5D3B);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  color: #FF5D3B;
}
</style>
